import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { BsArrowLeft } from "react-icons/bs"

import Layout from "../components/layout"
// import SEO from "../components/seo"
import { InnerHero } from "../components"
import ShareButtons from "../components/ShareButtons/ShareButtons"

export const query = graphql`
  query GetSingleBlog($slug: String) {
    singlePrismicPost: allPrismicPost(
      filter: { data: { slug: { text: { eq: $slug } } } }
    ) {
      nodes {
        data {
          post_author {
            text
          }
          post_author_image {
            alt
            dimensions {
              height
              width
            }
            fixed(width: 55, height: 55) {
              ...GatsbyPrismicImageFixed_withWebp
            }
          }
          post_author_info {
            text
            raw
            html
          }
          post_author_link {
            url
          }
          post_body {
            html
          }
          post_category
          post_date(formatString: "MMMM Do, YYYY")
          post_hero_image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
          post_preview_description {
            text
          }
          post_title {
            text
          }
          slug {
            text
          }
        }
        id
        uid
      }
    }

    metadata: site {
      siteMetadata {
        author
        siteUrl
        twitterUsername
      }
    }
  }
`

const BlogTemplate = ({ page, data, articletitle }) => {
  const blogs = data.singlePrismicPost.nodes

  // ShareButtons
  const { pathname } = useLocation()

  const { siteUrl, twitterUsername } = data.metadata.siteMetadata

  const title = `${data.singlePrismicPost.nodes[0].data.post_title.text}`
  const postImage = `${data.singlePrismicPost.nodes[0].data.post_hero_image.fluid.src}`
  const image = data.singlePrismicPost.nodes[0].data.post_hero_image.fluid.src
  const authorImage =
    data.singlePrismicPost.nodes[0].data.post_author_image.fixed
  const authorAlt = data.singlePrismicPost.nodes[0].data.post_author_image.alt
  const previewDescription = `${data.singlePrismicPost.nodes[0].data.post_preview_description.text}`
  const author = `${data.singlePrismicPost.nodes[0].data.post_author.text}`
  const url = `${siteUrl}${pathname}`
  const twitterHandle = "vtwebdev"
  const canonical = pathname ? url : null

  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        title={title}
        description={previewDescription}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
      >
        <meta name="author" content={twitterHandle} />
        <meta name="description" content={previewDescription} />
        <meta name="image" content={image} />
        {url && <meta property="og:url" content={url} />}

        {(articletitle ? true : null) && (
          <meta property="og:type" content="website" />
        )}

        {title && <meta property="og:title" content={title} />}

        {previewDescription && (
          <meta property="og:description" content={previewDescription} />
        )}

        {image && <meta property="og:image" content={image} />}

        {image && <meta property="og:image:alt" content={title} />}

        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:site" content={author} />

        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}

        {title && <meta name="twitter:title" content={title} />}

        {previewDescription && (
          <meta name="twitter:description" content={previewDescription} />
        )}

        {image && <meta name="twitter:image" content={image} />}
      </Helmet>

      <main>
        <section id="blog-template">
          {blogs &&
            blogs.map(item => {
              const { id } = item
              const {
                post_title,
                post_hero_image,
                post_date,
                post_author,
                post_body,
                post_author_info,
                slug,
              } = item.data
              const fluid = post_hero_image.fluid

              return (
                <>
                  <div className="section u-padding-bottom">
                    {!page && (
                      <>
                        <InnerHero>
                          <h1 className="heading__main-title heading__main-title--black">
                            {post_title.text}
                          </h1>
                        </InnerHero>
                      </>
                    )}
                  </div>
                  <BlogWrapper>
                    <div className="row no-padding-top">
                      <div className="col-1-of-1">
                        <article key={id}>
                          <Link to={`/blog/${slug.text}`}>
                            <figure>
                              <Image
                                fluid={fluid}
                                alt={post_hero_image.alt}
                                fadeIn={false}
                                loading="eager"
                              />
                            </figure>
                          </Link>
                          <div className="blog-template__author u-margin-bottom-medium">
                            {authorImage && (
                              <figure>
                                <Image
                                  fixed={authorImage}
                                  alt={authorAlt}
                                  fadeIn={false}
                                  loading="eager"
                                />
                              </figure>
                            )}

                            <p className="paragraph u-no-margin-bottom">
                              {post_author.text} / <time>{post_date}</time>
                            </p>

                            <p className="paragraph">
                              <div
                                className="template__text-body"
                                dangerouslySetInnerHTML={{
                                  __html: post_author_info.html,
                                }}
                              ></div>
                            </p>
                          </div>
                          <div className="blog-template__body">
                            <h2 className="blog-template__heading-secondary--title heading-secondary--title u-margin-bottom-medium">
                              {post_title.text}
                            </h2>
                            <p className="paragraph u-margin-bottom-medium">
                              <div
                                className="template__text-body"
                                dangerouslySetInnerHTML={{
                                  __html: post_body.html,
                                }}
                              ></div>
                            </p>
                          </div>
                        </article>
                      </div>
                    </div>

                    <div className="row u-padding-bottom blog-template__footer">
                      <div className="col-2-of-2 blog-template__footer--column">
                        <article>
                          <button aria-label="button">
                            <Link to={`/blog/`} className="paragraph__link">
                              <BsArrowLeft />
                              All Posts
                            </Link>
                          </button>
                        </article>

                        <article>
                          <p className="paragraph u-margin-bottom-medium">
                            Share this post:{" "}
                          </p>
                          <ShareButtons
                            className="react-share__ShareButton"
                            title={title}
                            url={url}
                            twitterHandle={twitterHandle}
                            image={postImage}
                          />
                        </article>
                      </div>
                    </div>
                  </BlogWrapper>
                </>
              )
            })}
        </section>
      </main>
    </Layout>
  )
}

const BlogWrapper = styled.section`
  position: relative;
  padding-top: 5rem;

  @media only screen and (max-width: 400px) {
    padding-top: 0;
  }

  @media only screen and (max-width: 400px) {
    .no-padding-top {
      padding-top: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .no-padding-top {
      padding-top: 0;
    }
  }

  .blog-template__body {
    max-width: 70rem;
    margin: 0 auto;

    .block-img img {
      width: 100%;
      height: auto;
    }

    h3 a {
      color: #000;
    }
  }
`

export default BlogTemplate
